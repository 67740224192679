import cx, { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import type { ReactNode, Key } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import type { MenuProps } from 'antd';
import { Avatar, Menu, Popover, Typography } from 'antd';
import { portfolioClient } from '@/api/apollo-client';
import gqlQueries from '../../../api/gql-queries';
import AI from '../../../public/ai.svg';
import Chart from '../../../public/chart.svg';
import Data from '../../../public/data.svg';
import Dollar from '../../../public/dollar.svg';
import Home from '../../../public/home.svg';
import InvestmentsRel from '../../../public/investmentRel.svg';
import LOGO from '../../../public/newLogoWithoutText.svg';
import LogOut from '../../../public/logOut.svg';
import FULL_LOGO from '../../../public/newLogoWithText.svg';
import Percentage from '../../../public/percentage.svg';
import Users from '../../../public/users.svg';
import { getInitials } from '@/utils/portfolio';
import NoSsrWrapper from '../../no-ssr-wrapper/no-ssr-wrapper';
import styles from './Navigation.module.scss';
import findRootObject from '@/utils/nav';
import type { IMenuCategory } from '@/types/nav';

function getItem(
  label: ReactNode,
  key?: Key | null,
  icon?: ReactNode,
  children?: IMenuCategory[],
): IMenuCategory {
  return {
    key,
    icon,
    children,
    label,
  } as IMenuCategory;
}

const items: IMenuCategory[] = [
  getItem('Home', '/home', <Home />),
  getItem('Data Management', 'dataManagement', <Data />, [
    getItem('Companies', '/data-management/companies'),
    getItem('Legal Entities', '/data-management/legal-entities'),
    getItem('Deals', '/data-management/deals'),
    getItem(
      'Merge with Aumni companies(Tax)',
      '/dashboard#merge-companies-tax',
    ),
    getItem(
      'Merge with Aumni companies(Finance)',
      '/dashboard#merge-companies-finance',
    ),
    getItem(
      'Merge Netsuite Duplicates',
      '/dashboard#merge-netsuite-duplicates-companies',
    ),
    getItem('Match Funds', '/dashboard#match-netsuite-funds'),
    // Hidden due to some problems on backend side
    // getItem(
    //     "Merge Netsuite Duplicates",
    //     "/dashboard#merge-netsuite-duplicates-funds"
    // ),
  ]),
  getItem('Tax', 'tax', <Percentage />, [
    getItem('Tax Transactions', '/tax/tax-transactions'),
    getItem('Tax Lot Review', '/tax/tax-lot-review'),
  ]),
  getItem('Finance', 'finance', <Dollar />, [
    getItem('Valuation Report', '/valuation-report'),
    getItem(
      'New Investment Activities',
      '/dashboard#new-investment-activities',
    ),
    getItem('Fund Overview', '/dashboard#fund-overview-download'),
    getItem(
      'Cash Balance/Planning',
      '/cash-balance-planning/pending-investments',
    ),
    getItem('Carta', '/dashboard#carta'),
  ]),

  getItem('Investment Team', 'investments', <Chart />, [
    getItem('Portfolio Modeling', '/portfolio'),
  ]),
  getItem('Investor Relations', 'investor', <InvestmentsRel />, [
    getItem('IR LP Profile', '/ir/lp-profile'),
    getItem('IR Co-Invest', '/coinvest-report'),
    getItem('Mandate Tracker (WIP)', '/ir/mandate-tracker/private-debt'),
  ]),
  getItem('HRIS/ManCo', 'hr', <Users />, [getItem('HR Page', '/hr')]),
  getItem('Intelligence', '/dashboard#intelligence-chat', <AI />),
];

interface IAvatarNameProps {
  collapsed: boolean;
  name?: string;
}

function AvatarName({ collapsed, name }: IAvatarNameProps) {
  const [openActions, setOpenActions] = useState(false);
  const router = useRouter();

  const userActions = () => {
    const onLogout = () => {
      localStorage.removeItem('authToken');

      datadogRum.addAction('logout');
      datadogRum.stopSession();

      router.push('/');
    };

    return (
      <div className={styles.logOutActionWrapper}>
        <div className={styles.logOutAction} onClick={onLogout}>
          <LogOut /> <Typography.Text>Log out</Typography.Text>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (collapsed) {
      setOpenActions(false);
    }
  }, [collapsed]);

  return (
    <Popover
      content={userActions()}
      placement="top"
      open={openActions}
      onOpenChange={setOpenActions}
      trigger="click"
    >
      <div
        className={cx(
          styles.avatarContainer,
          !collapsed && styles.fullWidthAvatar,
        )}
      >
        <>
          <Avatar className={styles.avatar}>{getInitials(name)}</Avatar>
          {!collapsed && (
            <Typography.Text className={styles.avatarName}>
              {name}
            </Typography.Text>
          )}
        </>

        {!collapsed && <EllipsisOutlined className={styles.actions} />}
      </div>
    </Popover>
  );
}

interface INavigationProps {
  openedNav?: boolean;
}

export function Navigation({ openedNav = false }: INavigationProps) {
  const [collapsed, setCollapsed] = useState(!openedNav);
  const [current, setCurrent] = useState('');
  const [showByDefault, setShowByDefault] = useState('');
  const router = useRouter();

  const [openedKeys, setOpenedKeys] = useState<string[]>([]);
  const [nextOpenKey, setNextOpenKey] = useState('');

  const { data: user } = useQuery(gqlQueries.GET_USER, {
    client: portfolioClient,
  });

  useEffect(() => {
    const category = findRootObject(items, router.asPath);

    category && setShowByDefault(category.key);
  }, [router]);

  useEffect(() => {
    if (!collapsed) {
      setOpenedKeys([nextOpenKey || showByDefault]);
    } else {
      setOpenedKeys([]);
    }
  }, [collapsed, nextOpenKey, showByDefault]);

  useEffect(() => {
    setCurrent(router.asPath);
  }, [router.asPath]);

  const onClick: MenuProps['onClick'] = (e) => {
    router.push(e.key).catch(console.error);

    setCurrent(e.key);
  };

  const onMouseEnter = () => {
    setCollapsed(false);
  };

  const onMouseLeave = () => {
    setCollapsed(true);
  };

  const handlers = !openedNav
    ? {
        onMouseEnter: onMouseEnter,
        onMouseLeave: onMouseLeave,
      }
    : {};

  const onOpenChange = (keys: string[]) => {
    if (keys.length) {
      const selectedSection = keys.pop() as string;
      setNextOpenKey(selectedSection);
    } else {
      setNextOpenKey('');
    }
  };

  return (
    <NoSsrWrapper>
      <div
        className={cx(
          styles.container,
          !collapsed && styles.fullWidthContainer,
          openedNav && styles.fullWidthContainer,
        )}
        {...handlers}
      >
        <div
          className={cx(
            styles.logoContainer,
            (!collapsed || openedNav) && styles.fullWidthLogo,
          )}
        >
          {collapsed && !openedNav ? (
            <div className={styles.logo}>
              <LOGO />
            </div>
          ) : (
            <FULL_LOGO />
          )}
        </div>
        <div className={styles.menuContainer}>
          <Menu
            openKeys={openedKeys}
            style={{
              borderInlineEnd: 'none',
            }}
            className={clsx(
              'custom-menu',
              collapsed && !openedNav && 'collapsed',
            )}
            onClick={onClick}
            selectedKeys={[current]}
            mode="inline"
            items={items}
            inlineCollapsed={collapsed}
            forceSubMenuRender
            onOpenChange={onOpenChange}
          />
        </div>
        <AvatarName collapsed={collapsed} name={user?.resolveUser.name} />
      </div>
    </NoSsrWrapper>
  );
}
