import {
  ApolloClient,
  createHttpLink,
  from,
  gql,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

import { getAuthToken } from '@/utils/auth';
import { createFragmentRegistry } from '@apollo/client/cache';
import logger from 'lib/logger';
import typeDefs from './schema';

const AUTH_ERROR = 'AUTH_ERROR';
const INVALID_TOKEN = 'Invalid token.';

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL + '/graphql',
  headers: {
    'access-control-allow-origin': '*',
  },
});

const NEW_BE_GRAPHQL_URL = new URL(
  `${process.env.NEXT_PUBLIC_NEW_API_URL}/graphql`,
);

const wsLink =
  // We don't have websocket implementation on SSR,
  // so we need to skip creating this link.
  typeof window === 'undefined'
    ? null
    : new GraphQLWsLink(
        createClient({
          url: `wss://${NEW_BE_GRAPHQL_URL.host}/graphql`,
          connectionParams: () => ({ Authorization: getAuthToken() }),
        }),
      );

const portfolioHttpLink = createHttpLink({
  uri: NEW_BE_GRAPHQL_URL.href,
  headers: {
    'access-control-allow-origin': '*',
  },
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: getAuthToken(),
  },
}));

const newBackendHttpLinkWithAuth = authLink.concat(portfolioHttpLink);

const newBackendLink = wsLink
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      newBackendHttpLinkWithAuth,
    )
  : newBackendHttpLinkWithAuth;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message === AUTH_ERROR || message === INVALID_TOKEN) {
        logger.error('Authentication error. Redirecting to auth page.');
        window.location.href = '/';
      }
    });

  if (networkError) {
    logger.error('Network error.', { networkError });
  }
});

const fragments = createFragmentRegistry(gql`
  fragment OperationResult on OperationResult {
    message
    success
  }

  fragment FundGroupLegacyAllFields on FundGroup {
    fund_group_name
    funds {
      fund_id
      fund_name
    }
  }

  fragment StringValueWithNoteAllFields on StringValueWithNote {
    note
    value
  }

  fragment BalanceAccountAllFields on BalanceAccount {
    accountName
    accountNumber
  }

  fragment FundGroupAllFields on FundGroup {
    fundGroupName
    funds {
      sfdcFundId
      sfdcFundName
    }
  }

  fragment LpContactAllFields on LpContact {
    sfdcContactId
    location
    name
    title
    email
    bio
  }

  fragment MandateTrackerAssistantQueryAllFields on MandateTrackerAssistantQuery {
    limit
    orderBy {
      column
      direction
    }
    parts {
      aggregateCommitment {
        ...ValueRangeAllFields
      }
      allocationPct {
        ...ValueRangeAllFields
      }
      allocationPctDiff {
        ...ValueRangeAllFields
      }
      allocationStatus
      allocationUsdMn {
        ...ValueRangeAllFields
      }
      allocationUsdMnDiff {
        ...ValueRangeAllFields
      }
      aumUsdMn {
        ...ValueRangeAllFields
      }
      coInvestInterest
      consultant
      existingProspectiveLp
      firmType
      gcFunds
      geographicPreferences
      locations {
        ...MandateTrackerLocationAllFields
      }
      next12MthsNoFundsMax {
        ...ValueRangeAllFields
      }
      next12MthsNoFundsMin {
        ...ValueRangeAllFields
      }
      next12MthsPlans
      next12MthsUsdMnMax {
        ...ValueRangeAllFields
      }
      next12MthsUsdMnMin {
        ...ValueRangeAllFields
      }
      providesSpecialSituationsAllocation
      steps {
        explanation
        output
      }
      strategyPreferences
      targetAllocationPct {
        ...ValueRangeAllFields
      }
      targetAllocationUsdMn {
        ...ValueRangeAllFields
      }
      typicalInvestmentUsdMnMax {
        ...ValueRangeAllFields
      }
      typicalInvestmentUsdMnMin {
        ...ValueRangeAllFields
      }
    }
  }

  fragment MandateTrackerLocationAllFields on MandateTrackerLocation {
    city
    country
    displayName
    isInternational
    stateCounty
  }

  fragment PendingInvestmentsCashValueAllFields on PendingInvestmentsCashValue {
    createdAt
    value
  }

  fragment TaskOperationResultAllFields on TaskOperationResult {
    message
    success
    taskId
  }

  fragment ValueRangeAllFields on ValueRange {
    max
    min
  }

  fragment FinancialsYearAllField on FinancialsYear {
    year
    actual {
      ...FinancialsQuarterAllField
    }
    budgeted {
      ...FinancialsQuarterAllField
    }
    projected {
      ...FinancialsQuarterAllField
    }
  }

  fragment FinancialsQuarterAllField on FinancialsQuarter {
    q1 {
      ...FinancialMetricsAllField
    }
    q2 {
      ...FinancialMetricsAllField
    }
    q3 {
      ...FinancialMetricsAllField
    }
    q4 {
      ...FinancialMetricsAllField
    }
  }

  fragment FinancialMetricsAllField on FinancialMetrics {
    revenue
    ebitda
    arr
    cash_in_bank
    amount_of_drawn_debt
    revenue_growth_rate
    ebitda_growth_rate
    arr_growth_rate
    cash_in_bank_growth_rate
    amount_of_drawn_debt_growth_rate
  }
`);

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  connectToDevTools: true,
  cache: new InMemoryCache({ fragments }),
  typeDefs,
});

export const portfolioClient = new ApolloClient({
  link: from([errorLink, newBackendLink]),
  connectToDevTools: true,
  cache: new InMemoryCache({ fragments }),
  typeDefs,
});
